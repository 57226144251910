<template>
    <div class="topbar" :class="{ 'login': $route.path.includes('session') || $route.path.includes('register') }">

        <b-dropdown class="" v-if="$route.path.includes('session') || $route.path.includes('register')" right>
            <template #button-content>
                <img :src="require(`../assets/images/flags/${$i18n.locale}.png`)" alt="" height="24px">
            </template>
            <b-dropdown-item v-for="option in $i18n.availableLocales" :key="option" @click="$root.changeLan(option)">
                <img :src="require(`../assets/images/flags/${option}.png`)" alt="" height="24px">
                <span class="ml-2">{{ $t(option) }}</span>
            </b-dropdown-item>
        </b-dropdown>
        <div v-if="$route.path.includes('session') || $route.path.includes('register')" class="auth-page">
            <img src="../assets/images/logo-text.png" alt="">

        </div>
        <div v-else-if="$route.path.includes('dashboard')" class="content-page">
            <div class="d-flex align-items-center">
                <div class="rank">
                    <img :src="require('../assets/images/rank/rank_' + $root.userInfo.user_group_id + '.png')" alt="">
                </div>
                <div class="rank_username flex-grow-1">
                    {{ $root.userInfo.username }}

                </div>
                <div class="d-flex align-items-center">
                    <div class="mr-4" @click="$router.push('/web/news')">
                        <img src="../assets/images/actions/news.png" alt="" height="24px" width="24px">
                    </div>
                    <div class="mr-4" @click="$router.push('/web/profile/profile')">
                        <img src="../assets/images/actions/user.png" alt="" height="30px" width="30px">
                    </div>
                    <b-dropdown class="position-relative top-0 right-0" right>
                        <template #button-content>
                            <img :src="require(`../assets/images/flags/${$i18n.locale}.png`)" alt="" height="26px">
                        </template>
                        <b-dropdown-item v-for="option in $i18n.availableLocales" :key="option"
                            @click="$root.changeLan(option)">
                            <img :src="require(`../assets/images/flags/${option}.png`)" alt="" height="26px ">
                            <span class="ml-2">{{ $t(option) }}</span>
                        </b-dropdown-item>
                    </b-dropdown>

                </div>
            </div>
            <div class="d-flex align-items-end justify-content-between">
                <div class="text-white text-left mt-4">
                    <div class="mb-1 d-flex align-items-center">
                        <p class="mb-0 font-weight-500">{{ $t('total_investment') }}</p>
                        <span class="ml-2">
                            <img v-if="!isHidden" @click="isHidden = true" src="../assets/images/dashboard/eye.png" alt="" height="20px">
                            <img v-else  @click="isHidden = false" src="../assets/images/dashboard/eye-slash.png" alt="" height="20px">

                        </span>
                    </div>
                    <h4 class="font-weight-600">{{ !isHidden? $root.parseFloatCustom($root.userInfo.invest): '******' }} <span>USDT</span></h4>
                </div>
                <div>
                    <b-button variant="secondary" @click="$router.push('/web/deposit')">{{ $t('deposit') }}</b-button>
                </div>

            </div>


        </div>
    </div>

</template>
<script>
export default {
    data() {
        return {
            companies: [
            ],
            isHidden: false
        };
    }
}
</script>

<style>
.login {
    background-image: url('../assets/images/bg/bg3.png') !important;
}
</style>
