import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import checkAuth from "./system/auth/authenticate.js";
Vue.use(Router);

// create new router

const routes = [
  {
    path: "/",
    component: () => import("./views/landing"),
    redirect: "landing",
    children: [
      {
        path: "landing",
        component: () => import("./views/landing/landing.vue"),
      },
    ],
  },
  {
    path: "/web",
    component: () => import("./views/menu"),
    redirect: "/web/dashboard",
    children: [
      {
        path: "dashboard",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/dashboard"),
      },
      {
        path: "invest",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/invest"),
      },
      {
        path: "wallet",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/wallet"),
      },
      {
        path: "commission",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/commission"),
      },
      {
        path: "member",
        beforeEnter: checkAuth,
        component: () => import("./views/menu/member"),
      },

    ]
  },
  {
    path: "/web/gac",
    component: () => import("./views/gac"),
    redirect: "/web/gac/info",
    children: [
      {
        path: "info",
        beforeEnter: checkAuth,
        component: () => import("./views/gac/info"),
      },
      {
        path: "purchase",
        beforeEnter: checkAuth,
        component: () => import("./views/gac/purchase"),
      },
      {
        path: "vesting_record",
        beforeEnter: checkAuth,
        component: () => import("./views/gac/vesting_record"),
      },
    ]

  },
  {
    path: "/web/profile",
    component: () => import("./views/profile"),
    redirect: "/web/profile/profile",
    children: [
      {
        path: "profile",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/main"),
      },
      {
        path: "gac_token_conversion",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/gac_token_conversion"),
      },
      {
        path: "gac_solana",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/gac_solana"),
      },
      {
        path: "security",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/security"),
      },
      {
        path: "gvv_master_debit_card",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/gvv_master_card.vue"),
      },
      {
        path: "gvv_visa_card",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/gvv_visa_card.vue"),
      },
      {
        path: "customer_support",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/customer_support.vue"),
      },
      {
        path: "forgetGA",
        beforeEnter: checkAuth,
        component: () => import("./views/profile/forgetGA.vue"),
      },
      {
        path: "customer_support_detail",
        beforeEnter: checkAuth,
        name: 'ticketDetails',
        props: true,
        component: () => import("./views/profile/customer_support_detail.vue"),
      },

    ]
  },
  {
    path: "/web/investTerminate",
    beforeEnter: checkAuth,
    component: () => import("./views/menu/investTerminate"),
  },
  {
    path: "/web/profile/witdhraw_address_book",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/createWalletAddress"),
  },
  {
    path: "/web/visa-preorder",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/visaPreOrder"),
  },
  {
    path: "/web/bonus_record",
    beforeEnter: checkAuth,
    component: () => import("./views/record/bonusRecord"),

  },
  {
    path: "/web/nft",
    beforeEnter: checkAuth,
    props: true,
    component: () => import("./views/menu/nft"),

  },
  {
    path: "/web/news",
    beforeEnter: checkAuth,
    props: true,
    component: () => import("./views/news/news"),

  },
  {
    path: "/web/newsDetails",
    name: 'newsDetails',
    beforeEnter: checkAuth,
    props: true,
    component: () => import("./views/news/newsDetails"),

  },
  {
    path: "/web/walletDetails",
    name: 'walletDetails',
    beforeEnter: checkAuth,
    props: true,
    component: () => import("./views/menu/walletDetails"),

  },
  {
    path: "/web/deposit",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/deposit"),

  },
  {
    path: "/web/deposit_gac",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/deposit_gac"),

  },
  {
    path: "/web/deposit_gacRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/deposit_gacRecord"),

  },
  {
    path: "/web/depositRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/depositRecord"),

  },
  {
    path: "/web/withdraw",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/withdraw"),

  },
  // {
  //   path: "/web/withdraw_gac",
  //   beforeEnter: checkAuth,
  //   component: () => import("./views/actions/withdraw_gac"),

  // },
  {
    path: "/web/withdraw_gacRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/withdraw_gacRecord"),

  },
  {
    path: "/web/withdrawRecord",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/withdrawRecord"),

  },
  {
    path: "/web/convert",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/convert"),

  },
  {
    path: "/web/investPackage",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/invest"),

  },
  {
    path: "/web/purchase_gac_token",
    beforeEnter: checkAuth,
    component: () => import("./views/actions/purchase_gac_token"),

  },
  {
    path: "/web/sessions",
    component: () => import("./views/sessions"),
    children: [
      {
        path: "login",
        component: () => import("./views/sessions/login"),
      },
      {
        path: "set_secpassword",
        component: () => import("./views/sessions/set_secpassword"),
      },
      {
        path: "forget_password",
        component: () => import("./views/sessions/forget_password"),
      },
      {
        path: "forget_secpassword",
        component: () => import("./views/sessions/forget_secpassword"),
      },
    ]
  },

  {
    path: "/register",
    component: () => import("./views/sessions/register"),
  },

  {
    path: "/coming_soon",
    component: () => import("./views/pages/coming_soon")
  },

  {
    path: "*",
    component: () => import("./views/pages/notFound")
  }
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
// var liveChat = document.getElementById('chat-widget-container');
router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.

   if (from.path.includes('manage')) {
    next();

  } else {
    if (to.path) {
      // Start the route progress bar.

      NProgress.start();
      NProgress.set(0.1);
    }
    next();

  }
});

router.afterEach(() => {
  var content = document.getElementById('top');
  if (content) {
    content.scrollTo({ top: 0, behavior: "smooth" });
  }
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 100);
});

export default router;
